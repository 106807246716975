import React from "react"
import { graphql } from "gatsby"
//import { useInView } from "react-cool-inview";

//above-the-fold
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ArticleHead from "../../components/blog-article/articleHead.js"


import "../../styles/blog-post.scss"
import "../../styles/wp-style.min.css"
import Newsletter from "../../components/blog-article/newsletter"
import ArticleBody from "../../components/blog-article/articleBody.js"
import SimilarArticles from "../../components/blog-article/similarArticles"
import ContactUs from "../../components/contactUs"


export const pageQuery = graphql`
query ($slug: String){
    wpPost(slug: {eq: $slug}) {
        slug
        title
        featuredImage {
              node {
               gatsbyImage(width: 1920)
               sourceUrl
               altText
               title
              }
            }
        excerpt
        content
    }
    #similarArticles: allWpPost(filter: {slug: {ne: $slug}}) {
    similarArticles: allWpPost(filter: 
	{slug: {in: [
      "preco-by-ste-aj-vy-mali-zakaznikom-ponukat-klientsku-zonu-tieto-benefity-musite-poznat",
      "kolko-vas-bude-stat-vyroba-alebo-preprogramovanie-softveru",
      "preco-vymenit-vasho-externeho-programatora-za-skusenu-it-firmu-tieto-dovody-vas-presvedcia"
    ]}}
	sort: {order: ASC, fields: excerpt}
	) {
        nodes{
            slug
            title
            featuredImage {
              node {
               gatsbyImage(width: 500)
               altText
               title
              }
            }
        }
    }

}   
`;


const BlogArticlePage = (pageQuery) => {

   //console.log(pageQuery);


    const {slug,title,featuredImage,excerpt,content} = pageQuery.data.wpPost;

    //var similarArticles = pageQuery.data.similarArticles.nodes.sort((a,b) => 0.5 - Math.random());
    var similarArticles = pageQuery.data.similarArticles.nodes;	

    var similarArticles = similarArticles.slice(0,3);


    return (
        <Layout pageName="blog-article-main">
            <SEO
                title={title}
                description={excerpt.replace(/<[^>]+>/g, '')}
                image={featuredImage.node.sourceUrl}
            />
            {/*<Newsletter />*/}
            <ArticleHead 
                title={title}
                banner_img={featuredImage.node}
            />
            <ArticleBody 
                description={excerpt}
                content={content}
            />
            <ContactUs 
            text={<>Ak Vás článok zaujal a mali<br/> By ste záujem o niektorú<br/> službu, napíšte nám</>} 
            button="Prekonzultujte to s nami zadarmo"
            />
            <SimilarArticles 
                similarArticles={similarArticles}
            />
        </Layout>
    )

    
}
  
  export default BlogArticlePage