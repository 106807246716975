import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";



const ArticleHead = (props) => {
    return(
        <section id="blog-post-banner">
            <div>
                {/*<GatsbyImage className="img" image={props.banner_img.gatsbyImage} alt={props.banner_img.altText} title={props.banner_img.title} placeholder="blurred" loading="eager" />*/}
		<img className="img" src={props.banner_img.sourceUrl} alt={props.banner_img.altText} title={props.banner_img.title} loading="eager" />
            </div>
            <h1>{props.title}</h1>
        </section>
    )
}

export default ArticleHead;