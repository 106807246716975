import React, { useEffect } from "react"
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";


const SimilarArticles = (props) => {


    useEffect(() => {
        let aside = document.querySelector('#suvisiace');
        const scrollFunc = () => {
            let contentSection =  document.querySelector('#blog-post-desc .content');
            let positionOfElement = contentSection.getBoundingClientRect();
            const currentScroll = window.pageYOffset;

            let mainHeading =  document.querySelector('h1').getBoundingClientRect();
            let header =  document.querySelector('header').getBoundingClientRect();

            var scrollBool = (currentScroll >= (window.pageYOffset + mainHeading.top) + mainHeading.height - header.height) && (currentScroll <= (window.pageYOffset + positionOfElement.top) + positionOfElement.height - window.innerHeight);

            if (scrollBool && !aside.classList.contains("show")) {
                aside.classList.add("show");
            } else if(!scrollBool && aside.classList.contains("show")) {
                    aside.classList.remove("show");
            }

        }
        window.addEventListener("scroll", scrollFunc);

        return () => {
            window.removeEventListener("scroll", scrollFunc);
        }
    },[])


    const similarArticles = props.similarArticles.map((node) =>
                <>
                <div>
                    <Link to={"/blog/"+ node.slug}>
                      {<GatsbyImage className="img" image={node.featuredImage.node.gatsbyImage} alt={node.featuredImage.node.altText} title={node.featuredImage.node.title} placeholder="blurred" loading="lazy" />}
                    </Link>
                </div>
                <Link to={"/blog/"+ node.slug}>{node.title}</Link>
                </>
    );

    return(
        <aside id="suvisiace" >
            <h4>Súvisiace články</h4>
            {similarArticles}
        </aside>
    )
}

export default SimilarArticles;
