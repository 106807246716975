import React, { useEffect } from "react"


const ArticleBody = (props) => {

    

    var content = props.content;
    const buttonCTA = "<a class='contact-button' href='mailto:mtoth@obs.sk?subject=Kontakt'>$1</a>";
    const buttonOBS_ACA = "<a target='_blank' class='contact-button' href='https://forms.gle/zhUjZTsyFyaWDPfU8'>$1</a>";
    content = content.replace(/\#CTA\#(.*?)\#CTA\#/,buttonCTA);
    content = content.replace(/\#OBS-ACA\#(.*?)\#OBS-ACA\#/,buttonOBS_ACA);

    content = content.replaceAll("data-src=","src");


    return(
        <>
        <section id="blog-post-desc" >
            <div className="description" dangerouslySetInnerHTML={{__html: props.description}}>
            </div>
            <div className="content wp-embed-responsive" dangerouslySetInnerHTML={{__html: content}} >
            </div>
        </section>
        
        </>
    )
}

export default ArticleBody;